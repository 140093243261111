<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="105">
    <line x1="1" x2="100" y1="4" y2="4" class="boundaryLine" style="stroke-width:1"></line>
    <line x1="1" x2="100" y1="12" y2="12" class="boundaryLine" style="stroke-width:1"></line>
    <rect :width="percentComplete" :fill="color" x="1" y="4" height="8"></rect>
    <line :x1="targetX" :x2="targetX" y1="0" y2="16" class="targetLine" style="stroke-width:1"></line>
    <title>{{ titleText }}</title>
  </svg>
</template>
<style>
  .boundaryLine {
    stroke: rgb(175,175,175);
  }
  .theme--dark.v-card .boundaryLine {
    stroke: rgb(150,150,150);
  }
  .targetLine {
    stroke: rgb(0,0,0)
  }
  .theme--dark.v-card .targetLine {
    stroke: rgb(255, 255, 255)
  }
</style>
<script>
export default {
  props: {
    percentComplete: Number,
    targetPercent: Number
  },
  computed: {
    targetX () {
      return this.targetPercent + 1
    },
    color () {
      let targetPercent = this.targetPercent
      if (Math.abs(targetPercent - this.percentComplete) <= 10) return this.$vuetify.theme.dark ? 'limegreen' : 'green' // Within 10% then inidcator should be green
      else if (targetPercent - this.percentComplete <= 20) return 'orange' // Within 20% then indicator should be yellow
      else return 'red' // If difference is outside 20% then indicator should be red
    },
    titleText () {
      return 'Target: ' + this.targetPercent + '%\nCompleted: ' + this.percentComplete + '%'
    }
  }
}
</script>
